import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/Button/Button";
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import axios from "axios";

// const NOTIFY_URL = 'http://localhost:80/notify';
const NOTIFY_URL = 'https://contact.creovia.io/';
const Contact = (props) => {

    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const phoneRef = useRef(null);
    const emailRef = useRef(props.email || null);
    const messageRef = useRef(null);
    const [status, setStatus] = useState({status: '', message: ''}); // ['idle', 'pending', 'success', 'error'

    useEffect(() => {
        if (props.email) {
            emailRef.current.value = props.email;
        }
    }, [props.email])

    const phoneNumberAutoFormat = (phoneNumber) => {
        const number = phoneNumber.trim().replace(/[^0-9]/g, "");
        if (number.length < 4) return number;
        if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
        if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
        return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    };

    const onPhoneNumberChange = (e) => {
        const targetValue = phoneNumberAutoFormat(e.target.value);
        phoneRef.current.value = targetValue;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus({ status: 'pending', message: 'Sending...' });
    
        if (!firstNameRef.current?.value || !phoneRef.current?.value || !emailRef.current?.value) {
            setStatus({ status: 'error', message: 'Please fill out required fields' });
            return;
        }
    
        const data = {
            first_name: firstNameRef.current?.value || '',
            last_name: lastNameRef.current?.value || '',
            phone: phoneRef.current?.value || '',
            email: emailRef.current?.value || '',
            text: messageRef.current?.value || ''
        };
        
        try {
            const res = await axios.post(NOTIFY_URL, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
        
            setStatus({ status: 'success', message: 'Thank you for contacting us! We will get back to you shortly.' });
    
            if (props.onSubmitCB) props.onSubmitCB(res);
        } catch (error) {
            console.error("Error:", error?.response?.data || error.message);
            setStatus({ status: 'error', message: 'There was an error submitting your request. Please try again.' });
        }
    };        

    return (
        <div
            onClick={(e) => { e.stopPropagation(); }}
            className="flex pointer-events-auto flex-col max-w-[1050px] w-full bg-light-secondary shadow rounded-3xl dark:bg-[#020617] dark:text-light-primary border-light-primary border border-opacity-20 gap-6 sm:p-16 p-8">
            <h3 className="text-blue-600 dark:text-blue-400 text-2xl" >Join our wait list</h3>
            <h3 className={"font-semibold text-[28px] mr-5"}>Ready to elevate your organization?</h3>
            <p className={"text-[20px]"}>Let's embark on a journey of innovation and growth together.</p>
            <form className={"flex flex-col gap-6"} onSubmit={handleSubmit}>
                <div className={"flex flex-col sm:flex-row gap-6 justify-between "}>
                    <div className={"flex flex-col gap-1 w-full"}>
                        <label htmlFor="businessName">First Name</label>
                        <input ref={firstNameRef} type={"text"} id="firstName"
                               className={"px-2 py-2 rounded-xl dark:bg-dark-tertiary dark:text-light-primary bg-[#F1F5F9]"}
                               placeholder={"First Name"}/>
                    </div>
                    <div className={"flex flex-col gap-1 w-full"}>
                        <label htmlFor="businessName">Last Name</label>
                        <input ref={lastNameRef} type={"text"} id="lastName"
                               className={"px-2 py-2 rounded-xl dark:bg-dark-tertiary dark:text-light-primary bg-[#F1F5F9]"}
                               placeholder={"Last Name"}/>
                    </div>
                </div>
                <div className={"flex flex-col sm:flex-row gap-6 justify-between "}>
                    <div className={"flex flex-col gap-1 w-full"}>
                        <label htmlFor="businessPhone">Phone</label>
                        <input ref={phoneRef} type={"tel"} id={"businessPhone"}
                               onChange={onPhoneNumberChange}
                               className={"px-2 py-2 rounded-xl dark:bg-dark-tertiary  dark:text-light-primary w-full bg-[#F1F5F9]"}
                               placeholder={"602-349-9222"}/>
                    </div>
                    <div className={"flex flex-col gap-1 w-full"}>
                        <label htmlFor="email">Email</label>
                        <input ref={emailRef} type={"email"} id={"email"}
                               className={"px-2 py-2 rounded-xl dark:bg-dark-tertiary dark:text-light-primary w-full bg-[#F1F5F9]"}
                               placeholder={"someone@example.com"}/>
                    </div>
                </div>
                <div className={"flex flex-col gap-1 w-full"}>
                    <label htmlFor="message">Message</label>
                    <textarea 
                        ref={messageRef} 
                        id={"message"}
                        className={"px-2 py-2 rounded-xl dark:bg-dark-tertiary dark:text-light-primary w-full bg-[#F1F5F9] min-h-[100px]"}
                        placeholder={"Tell us about your needs..."}
                    />
                </div>
                <div className={'flex justify-between items-center'}>
                    <div>
                        {status.status && <div className={"text-sm dark:text-light-primary"}>{status.message}</div>}
                    </div>
                    <Button className="px-4 bg-[#3B82F6] dark:bg-[#3B82F6]"
                        options={{
                            disabled: status.status === 'success' || status.status === 'pending',
                            type: "submit"
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default Contact;